<template>
  <div data-vue-name="DetailsOpponent" class="details-opponent">
    <div class="details-opponent__layout-grid-3row">
      <custom-header @click="goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />
          Details
        </template>
        <template #last>
          <btn-get-premium />
        </template>
      </custom-header>
      <main class="scroll-hide">
        <div class="details-opponent-vs">
          <div class="details-opponent-vs__wrap">
            <div class="details-opponent-vs__opponent">
              <div class="details-opponent-vs__avatar">
                <img :src="oponentData.initiator.photo" :alt="oponentData.initiator.name">
              </div>
              <div>
                <div class="d-flex">
                  <btn-get-premium />
                </div>
                <div class="details-opponent-vs__name">
                  {{ oponentData.initiator.name }}
                </div>
                <div class="details-opponent-vs__nic-name" v-if="hasNickname">{{ oponentData.initiator.nickname }}</div>
                <div class="d-flex w-100">
                  <div class="details-opponent-vs__country">
                    <div class="details-opponent-vs__country-img">
                      <img :src="oponentData.initiator.country.icon" :alt="oponentData.initiator.country.code">
                    </div>
                    {{ oponentData.initiator.country.code }}
                  </div>
                  <div class="details-opponent-vs__scores">
                    <template v-if="oponentData.initiator.experience">
                      <svg-icon size="size16x16" name="icoStar" />
                      {{ oponentData.initiator.experience }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-3">
          <div class="details-opponent-vs__athletes-data">
            <div class="details-opponent-vs__title">Athlete’s Data</div>
            <ul>
              <li>
                <span>Age</span>
                <div>
                  <template v-if="oponentData.initiator.age > 0">{{ oponentData.initiator.age }}</template>
                  <template v-else>none</template>
                </div>
              </li>
              <li>
                <span>Height</span>
                <div>
                  <template v-if="oponentData.initiator.height">{{ oponentData.initiator.height }}</template>
                  <template v-else>none</template>
                </div>
              </li>
              <li>
                <span>Weight</span>
                <div>
                  <template v-if="oponentData.initiator.weight">{{ oponentData.initiator.weight }}</template>
                  <template v-else>none</template>
                </div>
              </li>
              <li>
                <span>Ratio</span>
                <div>
                  <template v-if="oponentData.initiator.ratio">{{ oponentData.initiator.ratio }}</template>
                  <template v-else>none</template>
                </div>
              </li>
            </ul>
          </div>
          <div class="details-opponent-vs__venue-data">
            <div class="details-opponent-vs__title">Venue Data</div>
            <div class="d-flex justify-content-between">
              <div v-for="(item, key) in kindSportTypes" :key="item">
                <div class="details-opponent-vs__venue-data-name">{{ key }}</div>
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="details-opponent-vs__submission-date">
            <div class="details-opponent-vs__title">Submission Date</div>
            <div class="details-opponent-vs__submission-date-description mb-2">All videos must be submitted till the date
              mentioned</div>
            <div class="d-flex align-items-center">
              <svg-icon size="size16x16" name="icoDatepicker" class-name="me-1" />
              {{ eventData }}
              <svg-icon size="size16x16" name="icoTime" class-name="ms-2 me-1" />
              {{ eventTime }}
            </div>
          </div>
          <div class="details-opponent-vs__description-box">
            <div class="details-opponent-vs__title">Description & comments</div>
            <div v-if="oponentData.competition.description" class="details-opponent-vs__description">
              {{ oponentData.competition.description }}
            </div>
          </div>
          <div class="details-opponent-vs__venue-preview">
            <div class="details-opponent-vs__title">Venue Preview</div>
            <div class="details-opponent-vs__venue-preview-video">
              <btn-get-premium />
              <img src="../assets/images/v3.png" alt="">
            </div>
            <div class="details-opponent-vs__venue-description">Tokyo Aquatics Centre Olympics 2020</div>
          </div>
        </div>
      </main>
      <footer v-if="!isHideFooter && !oponentData.accepted_at && !oponentData.rejected_at"
        class="d-flex details-opponent__footer">
        <button class="btn-style1" @click="setAccept(oponentData.id)">
          <svg-icon size="size20x20" name="ico-check" class-name="me-2" />
          <span>Accept</span>
        </button>
        <button class="btn-style2" @click="setReject(oponentData.id)">
          <svg-icon size="size20x20" name="ico-close" class-name="me-2" />
          <span>Reject</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import BtnGetPremium from '@/components/BtnGetPremium'

export default {
  name: 'details-opponentOpponent',
  props: {
    oponentData: {
      type: Object,
      required: true,
    }
  },
  components: {
    BtnGetPremium,
  },
  computed: {
    eventData() {
      const date = this.oponentData?.competition.event_at

      if (!date) {
        return
      }

      return moment(date).format('DD MMM YYYY')
    },
    eventTime() {
      const date = this.oponentData?.competition.event_at

      if (!date) {
        return
      }

      return moment(date).format('hh:mm')
    },
    kindSportTypes() {
      const { types } = this.oponentData.competition.kind_sport
      const details = types.reduce((acc, item) => {
        if (item.values[0]) acc[item.name] = item.values[0].name
        return acc
      }, {})
      return details
    },
    hasNickname() {
      return this.oponentData.initiator.nickname && this.oponentData.initiator.nickname.length > 0
    }
  },
  data() {
    return {
      isHideFooter: false,
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'chat-user-invite',
        params: { chatUserData: this.oponentData },
      })
    },
    async setAccept(id) {
      this.isHideFooter = true
      await this.$store.dispatch('setAccept', id)
    },
    async setReject(id) {
      this.isHideFooter = true
      await this.$store.dispatch('setReject', id)
    },
  },
}
</script>

<style lang="stylus">
.details-opponent {
  height 100%;
}
.details-opponent__layout-grid-3row {
  display grid
  grid-template-columns 1fr
  grid-template-rows auto 1fr
  height 100%
  position relative
}
.details-opponent__layout-grid-3row main {
  height 100%
  overflow-y auto
  color: #D8D8D8;
  padding-bottom 157px
}
.details-opponent-vs {
  margin-bottom: 24px
}
.details-opponent-vs__wrap {
  display flex
  justify-content space-between
  padding 16px 16px 18px
  background: linear-gradient(180deg, rgba(210, 19, 65, 0) 0%, rgba(210, 19, 65, 0.15) 100%);
  border-radius: 0 0 16px 16px;
  align-items center
}
.details-opponent-vs__opponent {
  display flex
}
.details-opponent-vs__avatar {
  margin-bottom: 8px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 45%;
  display: flex;
  margin-right: 20px
}
.details-opponent-vs__opponent .btn-get-premium {
  margin-bottom: 12px
}
.details-opponent-vs__name {
  font-family: Poppins;
  color: #D8D8D8;
  margin-bottom: 8px
}
.details-opponent-vs__nic-name {
  color: #D21341;
  margin-bottom: 8px
}
.details-opponent-vs__country {
  margin-right: 10px
}
.details-opponent-vs__country, .details-opponent-vs__scores {
  display: flex;
  align-items: center;
  justify-content: center;
}
.details-opponent-vs__country-img {
  width: 16px;
  height: 12px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}
.details-opponent-vs__country-img img {
  object-fit: cover;
  width: 100%;
}

.details-opponent-vs__title {
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 8px
}
.details-opponent-vs__athletes-data,
.details-opponent-vs__venue-data,
.details-opponent-vs__submission-date,
.details-opponent-vs__description-box {
  margin-bottom: 24px
  padding-bottom 24px
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.details-opponent-vs__athletes-data ul {
  display flex
  align-items center
  justify-content space-between
}
.details-opponent-vs__athletes-data span,
.details-opponent-vs__description {
  color: #7B8B97;
}
.details-opponent-vs__venue-data-name,
.details-opponent-vs__submission-date-description {
  font-size: 12px;
  line-height: 140%;
  color: #7B8B97;
}
.details-opponent-vs__venue-preview-video {
  border-radius: 12px;
  overflow hidden
  margin-bottom: 8px
  position relative
}
.details-opponent-vs__venue-preview-video .btn-get-premium {
  position absolute
  top 16px
  right 16px
}
.details-opponent-vs__venue-preview-video img, .details-opponent-vs__venue-preview-video video {
  width 100%
  height 100%
  object-fit cover
}
.details-opponent-vs__venue-description {
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
}
.details-opponent__footer {
  background: linear-gradient(180deg, rgba(20, 23, 35, 0) 0%, #141723 44.62%);
  padding: 95px 16px 16px
  position absolute
  bottom 0
  left 0
  width 100%
  gap 16px
}
.details-opponent__footer button {
  height 46px
}
</style>