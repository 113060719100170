<template>
  <div
      data-vue-name="BtnGetPremium"
      class="btn-get-premium"
  >
    <button>
      <svg-icon size="size10x10" name="vipCrown-2" class-name="vip-crown-2"/>
      <span>GET PREMIUM</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BtnGetPremium"
}
</script>

<style lang="stylus">
.btn-get-premium {
  background: #D1B049;
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;
  min-height 18px
  display: inline-flex;
  align-items: center;
  button {
    display flex
    justify-content space-between
    align-items center
    span {
      font-size: 10px;
      line-height: 1;
      color: #141723;
    }
    svg {
      min-width 8.96px
      min-height  8.03px
    }
  }
  .vip-crown-2 {
    margin-right 5px
  }
}
</style>
